<template>
  <registration-layout>
    <div v-if="!result || result.length === 0">
      <div class="head text-center">
        <p class="text-3xl">
          <b-text
              id="kvk_number/input/title"
              :fallback="`${cocType} nummer invoeren`"
              :mapping="{cocType}"
          />
        </p>

        <p class="text-sm text-gray-500 mt-2">
          <b-text
              id="kvk_number/input/subtitle"
              fallback="Vul hier jouw {cocType}-nummer in."
              :mapping="{cocType}"
          />
        </p>
      </div>

      <form @submit.prevent="() => fetchResults(true)" class="mt-2 grid grid-cols-2 gap-y-3"
      >
        <div class="col-span-2">
          <b-label>{{ cocType }} nummer</b-label>
          <b-input :disabled="loading" v-model="form.kvk_number" :error="errors.kvk_number"/>
          <b-input-error :error="errors.kvk_number"/>
        </div>

        <b-button :disabled="loading" class="col-start-2">
          Controleren
        </b-button>
      </form>
    </div>

    <div v-else class="grid grid-cols-2 gap-y-3">
      <div class="head text-center col-span-2">
        <p class="text-3xl">
          <b-text id="kvk_number/success/title" :fallback="`Klopt dit ${cocType} nummer?`"/>
        </p>
        <p class="text-sm text-gray-500 mt-2">
          Zo niet, klik dan
          <b-link @click="result = undefined" class="normal-case">hier</b-link>
          om een ander {{ cocType }} nummer in te voeren.
        </p>
      </div>

      <KvKResult class="col-span-2" :kvk="result" :coc-type="cocType"/>

      <b-button type="button" @click="save" class="col-start-2">
        Ja, ga door
      </b-button>
    </div>
  </registration-layout>
</template>

<script>
import RegistrationLayout from "@/layouts/RegistrationLayout";
import BLink from "@/modules/Link";
import KvKResult from "@/modules/KvKResult";
import BButton from "@/modules/Button";
import {mapGetters} from "vuex";
import BLabel from "@/modules/Label";
import BInput from "@/modules/Input";
import BInputError from "@/modules/InputError";
import {redirectToCompanyStep} from "@/router";
import BText from "@/modules/Text";

export default {
  name: "KvKResults",
  components: {
    BText,
    BInputError,
    BInput,
    BLabel,
    BButton,
    KvKResult,
    BLink,
    RegistrationLayout
  },
  data: () => ({
    loading: true,
    form: {
      kvk_number: null,
      street: null,
      house_number: null,
      house_number_ext: null,
      postcode: null,
      city: null
    },
    errors: {},
    result: undefined
  }),

  created() {
    if (this.$store.state.company) {
      if (this.$store.getters['company'].kvk_number) this.form.kvk_number = this.$store.getters['company'].kvk_number
      this.fetchResults();
    }
  },

  computed: {
    ...mapGetters({
      company: "company"
    }),

    cocType() {
      return (this.company.country_code === "BE") ? "KBO" : "KvK";
    },
  },

  methods: {
    fetchResults(check = false) {
      this.loading = true;
      this.errors = false;

      (check
          ? this.$axios.post("kvk_results", this.form)
          : this.$axios.post("kvk_results"))
          .then(({data}) => {
            this.result = data.result;
          })
          .catch(({response}) => {
            if (!check) this.form.kvk_number = "";
            else this.errors = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
    },

    save() {
      this.$axios.patch(
          "company/" + this.$store.state.company.id + "/",
          {
            kvk_number: this.result.number
          })
          .then(({data}) => {
            this.$store.commit("setCompany", data);

            this.$router.push(redirectToCompanyStep(this.$router));
          });
    }
  },

  watch: {
    "$store.state.company": {
      deep: true,
      handler() {
        this.fetchResults();
      }
    }
  }
};
</script>

<style scoped>

</style>
