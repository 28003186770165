<template>
    <div
        @click.prevent.self="proxyChecked = !proxyChecked"
        :class="disabled ? 'opacity-50' : ''"
        class="flex items-center"
    >
        <div class="relative flex items-center justify-center" @click="proxyChecked = !proxyChecked">
            <input
                @change="$emit('input', $event.target.checked)"
                type="checkbox"
                :disabled="disabled"
                :checked="value"
                :class="[
                    'transition ease-in-out duration-150',
                    'appearance-none h-5 w-5 border flex-shrink-0 cursor-pointer',
                    'text-primary focus:ring-primary rounded',
                    value ? 'bg-primary' : 'bg-transparent',
                    !!error ? 'border-red-500' : 'border-gray-300',
                ]"
            />
            <svg v-if="value" class="h-4 w-4 text-white absolute" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
        </div>
        <label @click.self.prevent="proxyChecked = !proxyChecked" class="ml-2 block text-sm text-gray-900 cursor-pointer">
            <slot></slot>
        </label>
    </div>
</template>

<script>
export default {
    name: "BCheckbox",

    props: ["value", 'error', "disabled"],

    data: () => ({
        proxyChecked: false
    }),

    created() {
        this.proxyChecked = this.value;
    },

    watch: {
        proxyChecked() {
            this.$emit("input", this.proxyChecked);
        }
    }
};
</script>

<style scoped>

</style>