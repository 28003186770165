<template>
    <registration-layout>
        <template #title><b-text id="user_account/title" fallback="Maak je profiel aan" /></template>
        <template #subtitle>
            <b-text id="user_account/subtitle" fallback="We vragen deze gegevens van je om jouw goederen te kunnen transporteren en in te klaren bij de douane." />
        </template>

        <template>
            <form
                class="my-5 grid grid-cols-1 gap-y-4"
                @submit.prevent="submit"
            >
                <div>
                    <b-label>Voornaam</b-label>
                    <b-input :disabled="processing" placeholder="Hoe we jou aan zullen spreken" type="text" class="mt-1" v-model="form.first_name" :error="errors.first_name" />
                    <b-input-error :error="errors.first_name" />
                </div>

                <div>
                    <b-label>E-mailadres</b-label>
                    <b-input :disabled="processing" class="mt-1" type="email" v-model="form.email" placeholder="forwarding@cargomate.nl" :error="errors.email" />
                    <b-input-error :error="errors.email" />
                </div>

                <div>
                    <b-label>Telefoonnummer</b-label>
                    <b-phone-input :disabled="processing" class="mt-1" v-model="form.phone" :error="errors.phone" />
                    <b-input-error :error="errors.phone" />
                </div>

                <div class="grid sm:grid-cols-2 grid-cols-1 gap-x-4">
                    <div>
                        <b-label>Wachtwoord</b-label>
                        <b-password-input :disabled="processing" :hide-password="hidePassword" @password="hidePassword = !hidePassword" class="mt-1" type="password" v-model="form.password" :error="errors.password" />
                        <b-input-error :error="errors.password" />
                    </div>

                    <div>
                        <b-label>Herhaal wachtwoord</b-label>
                        <b-password-input :disabled="processing" :hide-password="hidePassword" @password="hidePassword = !hidePassword" class="mt-1" type="password" v-model="form.password_confirmation" :error="errors.password_confirmation" />
                        <b-input-error :error="errors.password_confirmation" />
                    </div>
                </div>

                <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 gap-y-4 mt-2 items-start">
                    <div class="left">
                        <b-checkbox :disabled="processing || user" v-model="form.agree_tos" :error="errors.agree_tos">
                            Ja, ik ga akkoord met de
                            <a
                                @click.self="openTos"
                                class="normal-case text-primary underline"
                            >
                                Algemene Voorwaarden
                            </a>
                        </b-checkbox>
                        <b-input-error :error="errors.agree_tos" />
                    </div>

                    <b-button
                        :disabled="processing"
                        type="submit"
                    >
                        Volgende stap
                    </b-button>
                </div>
            </form>

            <hr>

            <b-link @click="showLoginModal = true" class="text-center mt-2">
                Heb je al een account?
            </b-link>
        </template>

        <b-login-modal :show="showLoginModal" @close="showLoginModal = false" />
    </registration-layout>
</template>

<script>
import RegistrationLayout      from "@/layouts/RegistrationLayout";
import BInput                  from "@/modules/Input";
import BLabel                  from "@/modules/Label";
import BPhoneInput             from "@/modules/PhoneInput";
import BPasswordInput          from "@/modules/PasswordInput";
import BCheckbox               from "@/modules/Checkbox";
import BButton                 from "@/modules/Button";
import BInputError             from "@/modules/InputError";
import BLink                   from "@/modules/Link";
import BLoginModal             from "@/partials/LoginModal";
import {mapGetters}            from "vuex";
import {redirectToCompanyStep} from "@/router";
import BText                   from "@/modules/Text";

export default {
    name: "UserAccount",
    components: {
        BText,
        BLoginModal,
        BLink,
        BInputError,
        BButton,
        BCheckbox,
        BPasswordInput,
        BPhoneInput,
        BLabel,
        BInput,
        RegistrationLayout
    },

    data: () => ({
        form: {
            first_name: "",
            email: "",
            phone: "",
            password: "",
            password_confirmation: "",
            agree_tos: false
        },

        errors: {},

        processing: false,

        hidePassword: true,

        showLoginModal: false,
    }),

    created() {
        if (this.$route.query.voornaam)
            this.form.first_name = this.$route.query.voornaam;

        if (this.$route.query.emailadres)
            this.form.email = this.$route.query.emailadres

        if (this.user) {
            this.form.first_name = this.user.first_name;
            this.form.email = this.user.email;
            this.form.phone = this.user.phone;
            this.form.agree_tos = true;
        }
    },

    computed: {
        ...mapGetters({
            user: "user",
            company: "company",
            texts: "texts",
        }),

        passwordConfirmed() {
            return this.password === this.password_confirmation;
        }
    },

    methods: {
        openTos() {
            open('https://www.cargomate.nl/juridisch/algemene-voorwaarden', '_blank')
        },

        submit() {
            this.processing = true;

            let query = this.$route.query
            let referred_by = parseInt(query.referred_by);

            console.log(this.$route.query);
            console.log(referred_by);

            if (Number.isInteger(referred_by) && referred_by > 0) {
                console.log(referred_by);
                this.form.referred_by = referred_by;
            }

            // UTM data
            this.form.utm_term = query.utm_term !== undefined ? query.utm_term : null;
            this.form.utm_source = query.utm_source !== undefined ? query.utm_source : null;
            this.form.utm_medium = query.utm_medium !== undefined ? query.utm_medium : null;
            this.form.utm_content = query.utm_content !== undefined ? query.utm_content : null;
            this.form.utm_campaign = query.utm_campaign !== undefined ? query.utm_campaign : null;

            // Google Click ID
            this.form.gclid = query.gclid !== undefined ? query.gclid : null;

            (this.user
                ? this.$axios.patch("user_account", this.form)
                : this.$axios.post("user_account", this.form))
                .then(({data}) => {
                    this.errors = {};

                    this.$store.commit("setUser", {user: data.user, token: data.token});
                    this.$store.commit("setCompany", data.company)

                    this.$router.push(redirectToCompanyStep(this.$router));
                }).catch(({response}) => {
                this.errors = response.data;
            }).finally(() => {
                this.processing = false;
            });
        }
    }
};
</script>

<style scoped>

</style>