<template>
    <div class="h-screen w-screen flex">
        <aside class="hidden lg:flex lg:max-w-xs whitespace-nowrap max-w-min w-1/6 border-r border-gray-100 w-full flex flex-col justify-center px-10">
            <b-steps />
        </aside>

        <div class="body flex-grow flex flex-col justify-between items-center py-4">
            <div class="grid grid-cols-3 w-full items-stretch">
                <div>
                    <b-link @click="back" v-if="!!company && !company.complete" class="flex items-center ml-3 self-stretch h-full">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                        <span class="ml-2">Vorige</span>
                    </b-link>
                </div>
                <div class="flex justify-center" :class="!!company ? '' : 'col-start-2'">
                    <img src="../assets/logo.png" alt="Cargomate" style="margin-top: 10px;">
                </div>
                <div class="text-sm pr-10 flex flex-col lg:flex-row items-center lg:justify-end justify-center" v-if="user">
                    <p>
                        Ingelogd als {{ user.first_name }}.
                    </p>
                    <b-link @click="logout" class="lg:ml-2 lg:mt-0 mt-1">Uitloggen</b-link>
                </div>
            </div>

            <div v-if="!loading" :class="maxWidth" class="w-full grid grid-cols-1 gap-y-4">
                <div class="head text-center">
                    <p class="text-3xl">
                        <slot name="title"></slot>
                    </p>
                    <p class="text-sm text-gray-500 mt-2">
                        <slot name="subtitle"></slot>
                    </p>
                </div>
                <slot></slot>
            </div>

            <div v-else>
                <p class="text-3xl mb-4">Laden...</p>

                <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000">
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(1 1)" stroke-width="2">
                            <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
                            </path>
                        </g>
                    </g>
                </svg>
            </div>

            <div class="footer flex justify-center center">
                <b-link
                    class="text-center"
                    href="https://www.cargomate.nl/juridisch/privacy-verklaring"
                    target="_blank"
                >
                    Privacy Verklaring
                </b-link>

                <b-link
                    class="text-center ml-4"
                    href="https://help.cargomate.nl/nl/"
                    target="_blank"
                >
                    Help center
                </b-link>
            </div>
        </div>

        <phone-verification-modal v-if="user && !loading" :user-show="showPhoneModal" />
    </div>
</template>

<script>
import BSteps                  from "@/partials/Steps";
import BLink                   from "@/modules/Link";
import {mapGetters}            from "vuex";
import PhoneVerificationModal  from "@/modules/PhoneVerificationModal";
import {redirectToCompanyStep} from "@/router";

export default {
    name: "RegistrationLayout",
    components: {
        PhoneVerificationModal,
        BLink,
        BSteps
    },
    props: {
        maxWidth: {
            type: String,
            default: "max-w-md"
        }
    },

    data: () => ({
        loading: true,
    }),

    created() {
        if (!this.texts) {
            this.loading = true;

            this.$store.dispatch("fetchTexts")
                .finally(() => {
                    this.loading = false;
                });
        } else {
            this.loading = false;
        }
    },

    computed: {
        ...mapGetters({
            user: "user",
            company: "company",
            texts: "texts"
        }),

        showPhoneModal() {
            return this.user && !this.user.phone_number_verified;
        }
    },

    methods: {
        logout() {
            this.$store.commit("logout");

            this.$router.push({name: "userAccount"})
                .catch(() => {
                    this.$router.go();
                });

        },

        back() {
            let newRoute = this.$router.getRoutes().reduce((carry, route, index) => {
                if (carry === -1) carry = route.name === this.$route.name ? index : -1;

                return carry;
            }, -1);

            newRoute = this.$router.getRoutes()[newRoute - 1];

            if (!newRoute || !this.company) {
                return;
            }

            this.$axios.patch(
                `company/${this.company.id}/`,
                {step: newRoute.meta.stepName}
            ).then(({data}) => {
                this.$store.commit("setCompany", data);

                console.log(data.step);

                this.$router.push(redirectToCompanyStep(this.$router));
            });
        }
    }
};
</script>

<style scoped>

</style>