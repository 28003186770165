<template>
    <registration-layout>
        <template #title>
            <b-text
                id="eori_number/title"
                fallback="EORI nummer"
            />
            </template>
        <template #subtitle>
            <b-text
                id="eori_number/subtitle"
                fallback="Om je boekingen te importeren, hebben we het EORI nummer nodig, dat bij jouw bedrijf hoort. Hier kun je die vinden."
            />
        </template>

        <template>
            <form @submit.prevent="check">

                <div>
                    <b-label>EORI nummer</b-label>
                    <b-input :disabled="processing" placeholder="Bijvoorbeeld: NL123456789" v-model="form.eori_number" type="text" :error="errors.eori_number" />
                    <b-input-error :error="errors.eori_number" />
                </div>

                <div class="mt-5">
                    <b-checkbox v-model="form.has_article_23">
                        Ben je in het bezit van een Artikel 23?
                    </b-checkbox>

                    <b-text
                        class="text-sm text-gray-500 mt-2"
                        id="eori_number/article_23"
                        fallback="Klik hier om meer te leren over een Artikel 23."
                    />
                </div>

                <div class="grid grid-cols-1 sm:grid-cols-2 mt-4">
                    <b-button :disabled="processing" type="submit" class="col-start-2">Controleren</b-button>
                </div>
            </form>
        </template>
    </registration-layout>
</template>

<script>
import RegistrationLayout      from "@/layouts/RegistrationLayout";
import BLabel                  from "@/modules/Label";
import BInputError             from "@/modules/InputError";
import BInput                  from "@/modules/Input";
import BButton                 from "@/modules/Button";
import {mapGetters}            from "vuex";
import {redirectToCompanyStep} from "@/router";
import BCheckbox               from "@/modules/Checkbox";
import BText                   from "@/modules/Text";

export default {
    name: "EORINumber",
    components: {
        BText,
        BCheckbox,
        BButton,
        BInput,
        BInputError,
        BLabel,
        RegistrationLayout
    },

    data: () => ({
        form: {
            eori_number: "",
            has_article_23: false,
        },

        errors: {},

        processing: false
    }),

    computed: {
        ...mapGetters({
            company: "company"
        })
    },

    created() {
        if (this.company.eori_number) {
            this.form.eori_number = this.company.eori_number;
            this.form.has_article_23 = this.company.has_article_23;
        }
    },

    methods: {
        check() {
            this.processing = true;
            this.errors = {}

            this.$axios.patch(`company/${this.company.id}/`, this.form)
                .then(({data}) => {
                    this.$store.commit("setCompany", data);

                    this.$router.push(redirectToCompanyStep(this.$router))
                })
                .catch(({response}) => {
                    this.errors = response.data;
                })
                .finally(() => {
                    this.processing = false;
                });
        }
    }
};
</script>

<style scoped>

</style>