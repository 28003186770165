<template>
  <div class="h-full grid grid-cols-1 gap-y-4 group">
    <div
        v-for="(option, index) in steps"
        :key="index"
        class="flex items-center"
        @click="() => goTo(option)"
    >
      <div
          class="index h-5 w-5 bg-gray-300 rounded-full relative flex flex-shrink-0 justify-center items-center"
          :class="[
                    isActiveOrPassed(index) ? 'bg-primary text-white' : 'bg-gray-300 text-gray-600'
                ]"
      >
        <span class="leading-none text-sm" v-if="!isPassed(index)">{{ index + 1 }}</span>
        <span v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                     d="M5 13l4 4L19 7"/></svg>
                </span>
      </div>
      <p
          class="ml-3"
          :class="[ isActive(index) ? 'font-bold text-gray-900' : '']"
      >{{ option.label }}</p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {redirectToCompanyStep} from "@/router";

export default {
  name: "BSteps",

  data: () => ({
    steps: [
      {
        label: "Profiel aanmaken",
        routeName: "userAccount",
        index: 0
      },
      {
        label: "BTW nummer",
        routeName: "vatNumber",
        index: 1
      },
      {
        label: "EORI nummer",
        routeName: "eoriNumber",
        index: 2
      },
      {
        label: "KvK/KBO nummer",
        routeName: "kvkNumber",
        index: 3
      },
      {
        label: "KvK/KBO uittreksel",
        routeName: "kvkExtract",
        index: 4
      },
      {
        label: "Persoonlijke gegevens",
        routeName: "userDetails",
        index: 5
      },
      {
        label: "Directe vertegenwoordiging",
        routeName: "signDv",
        index: 6
      },
      {
        label: "Voltooid",
        routeName: "success",
        index: 7
      }
    ]
  }),

  computed: {
    ...mapGetters({
      company: "company"
    })
  },

  methods: {
    isActive(index) {
      let currentIndex = this.steps.filter(r => r.routeName === this.$route.name)[0].index;
      return currentIndex === index;
    },

    isActiveOrPassed(index) {
      let currentIndex = this.steps.filter(r => r.routeName === this.$route.name)[0].index;
      return currentIndex >= index;
    },

    isPassed(index) {
      let currentIndex = this.steps.filter(r => r.routeName === this.$route.name)[0].index;
      return currentIndex > index;
    },


    goTo(route) {
      if (!this.company) return;

      let newRoute = this.$router.getRoutes().filter(r => r.name === route.routeName)[0];

      if (!newRoute.meta.stepName) return;

      this.$axios.patch(
          `company/${this.company.id}/`,
          {step: newRoute.meta.stepName}
      ).then(({data}) => {
        this.$store.commit("setCompany", data);

        this.$router.push(redirectToCompanyStep(this.$router))
      });
    }
  }
};
</script>

<style scoped>

</style>