<template>
    <registration-layout>
        <template #title>
            <b-text
                id="user_details/title" fallback="Persoonlijke gegevens"
            />
        </template>
        <template #subtitle>
            <b-text
                id="user_details/subtitle"
                fallback="We hebben deze gegevens nodig om goederen in te klaren in jouw naam bij de douane. <br> <b>Let op:</b> Zorg ervoor dat je de voor- en achternamen zo invult als dat ze op je paspoort/identiteitskaart staan. <br>"
            />
            <b-link class="normal-case" @click="showExampleForm = true">
                <b-text
                    id="user_details/subtitle/example"
                    fallback="Klik hier om te zien welke velden we op jouw identiteitsbewijs vereisen."
                />
            </b-link>
        </template>

        <template>
            <form class="mt-3 grid gap-y-3" @submit.prevent="save">

                <div>
                    <b-label>Voornamen</b-label>
                    <b-input :disabled="processing" v-model="form.first_name" :error="errors.first_name" />

                    <div class="mt-1">
                        <p v-if="!errors.first_name" class="text-xs text-gray-600">Zoals die op je paspoort/identiteitskaart staan</p>
                        <b-input-error v-else :error="errors.first_name"></b-input-error>
                    </div>
                </div>

                <div>
                    <b-label>Achternaam</b-label>
                    <b-input :disabled="processing" v-model="form.last_name" :error="errors.last_name" />

                    <b-input-error :error="errors.last_name"></b-input-error>
                </div>

                <div>
                    <b-label>Functie binnen bedrijf</b-label>
                    <b-input :disabled="processing" placeholder="Bijvoorbeeld: Eigenaar, CEO, Zaakvoerder" v-model="form.role" :error="errors.role" />

                    <b-input-error :error="errors.role"></b-input-error>
                </div>

                <div class="grid grid-cols-1 sm:grid-cols-2">
                    <b-button type="submit" :disabled="processing" class="sm:col-start-2 sm:col-span-1 col-span-2">Volgende stap</b-button>
                </div>
            </form>
        </template>

        <b-modal :show="showExampleForm" @close="showExampleForm = false">
            <template #default>
                <div class="p-3">
                    <b-text
                        id="user_details/example/text"
                        fallback="In het eerste rode vlakje zie jouw achternaam. In het tweede rode vlakje zie jouw voornamen."
                    />
                    <img src="/id-voorbeeld.png" class="mx-auto " />
                </div>
            </template>
        </b-modal>
    </registration-layout>
</template>

<script>
import RegistrationLayout      from "@/layouts/RegistrationLayout";
import BLabel                  from "@/modules/Label";
import BInput                  from "@/modules/Input";
import BInputError             from "@/modules/InputError";
import BButton                 from "@/modules/Button";
import {redirectToCompanyStep} from "@/router";
import BModal                  from "@/modules/Modal";
import BLink                   from "@/modules/Link";
import {mapGetters}            from "vuex";
import BText                   from "@/modules/Text";

export default {
    name: "UserDetails",
    components: {
        BText,
        BLink,
        BModal,
        BButton,
        BInputError,
        BInput,
        BLabel,
        RegistrationLayout
    },

    data: () => ({
        form: {
            first_name: "",
            last_name: "",
            role: ""
        },

        errors: {},

        processing: false,

        showExampleForm: false
    }),

    created() {
        if (this.company.first_name && this.company.last_name && this.company.role) {
            this.form = {
                first_name: this.company.first_name,
                last_name: this.company.last_name,
                role: this.company.role
            };
        }
    },

    computed: {
        ...mapGetters({
            company: "company"
        })
    },

    methods: {
        save() {
            this.processing = true;

            this.$axios.patch(
                "company/" + this.$store.getters["company"].id + "/",
                this.form
                )
                .then(({data}) => {
                    this.$store.commit("setCompany", data);

                    this.$router.push(redirectToCompanyStep(this.$router));
                })
                .catch(({response}) => {
                    this.errors = response.data;
                })
                .finally(() => {
                    this.processing = false;
                });
        }
    }
};
</script>

<style scoped>

</style>