<template>
    <b-modal :show="show && !changePhone">
        <div>
            <div class="head border-b border-gray-100 px-4 py-3">
                <p class="font-bold text-xl text-gray-800">
                    <b-text
                        id="sms_verification/title"
                        fallback="Voordat je doorgaat..."
                        :mapping="{phone: user.phone }"
                    />
                </p>
            </div>

            <form @submit.prevent="verify">
                <div class="px-4 mt-3">
                    <p class="mt-1">
                        <b-text
                            id="sms_verification/text"
                            fallback="vragen wij je jouw telefoonnummer te verifiëren, zodat we met jou contact op kunnen nemen mocht er iets fout gaan met een van jouw boekingen. We hebben een SMS'je met een eenmalige code gestuurd naar het door jou opgegeven telefoonnummer. Om je telefoonnummer te verifiëren, voer je hieronder die eenmalige code in en klik je op 'Code verifiëren'"
                            :mapping="{phone: user.phone }"
                        />
                    </p>

                    <div class="rounded-md bg-red-50 p-4 mt-3" v-if="error || errors.error">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <!-- Heroicon name: solid/x-circle -->
                                <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <div class="ml-3">
                                <h3 class="text-sm font-medium text-red-800">
                                    {{ error || errors.error }}
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="rounded-md bg-green-50 p-4 mt-3" v-if="success">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <!-- Heroicon name: solid/check-circle -->
                                <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <div class="ml-3">
                                <h3 class="text-sm font-medium text-green-800">
                                    Er is opnieuw een code verstuurd!
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 flex flex-col">
                        <b-label>Eenmalige code</b-label>
                        <b-input v-model="form.otp" :error="errors.otp" />
                        <b-input-error :error="errors.otp" />

                        <b-link class="normal-case mt-2" @click="changePhone = true">Telefoonnummer aanpassen</b-link>
                    </div>
                </div>

                <div class="px-4 pb-4 mt-3 border-t border-gray-100">
                    <div class="grid grid-cols-1 sm:grid-cols-2 mt-4 items-center text-center">
                        <b-link :disabled="processing" @click="() => send(true)">Code opnieuw versturen</b-link>
                        <b-button type="submit" :disabled="processing">Code verifiëren</b-button>
                    </div>
                </div>
            </form>
        </div>

        <b-modal :show="changePhone">
            <form @submit.prevent="sendPhoneChange">
                <div class="head border-b border-gray-100 px-4 py-3">
                    <p class="font-bold text-xl text-gray-800">
                        Telefoonnummer wijzigen
                    </p>
                </div>

                <div class="px-4 mt-3">
                    <div>
                        <b-label>Telefoonnummer</b-label>
                        <b-phone-input v-model="changeForm.phone" />
                    </div>
                </div>

                <div class="px-4 pb-4 mt-3 border-t border-gray-100">
                    <div class="grid grid-cols-1 sm:grid-cols-2 mt-4 items-center text-center">
                        <b-link @click="changePhone = false">Annuleren</b-link>
                        <b-button type="submit" class="sm:col-start-2 col-start-1" :disabled="processing">Telefoonnummer aanpassen</b-button>
                    </div>
                </div>
            </form>
        </b-modal>
    </b-modal>
</template>

<script>
import BModal       from "@/modules/Modal";
import BInput       from "@/modules/Input";
import BLabel       from "@/modules/Label";
import BButton      from "@/modules/Button";
import BLink        from "@/modules/Link";
import {mapGetters} from "vuex";
import BText        from "@/modules/Text";
import BPhoneInput  from "./PhoneInput";
import BInputError  from "./InputError";

export default {
    name: "PhoneVerificationModal",
    components: {
        BInputError,
        BPhoneInput,
        BText,
        BLink,
        BButton,
        BLabel,
        BInput,
        BModal
    },
    props: {
        userShow: Boolean
    },

    data: () => ({
        show: false,

        success: false,
        error: false,
        errors: {},

        form: {
            otp: ""
        },

        processing: false,

        changePhone: false,

        changeForm: {
            phone: ""
        }
    }),

    computed: {
        ...mapGetters({
            user: "user",
            texts: "texts"
        })
    },

    mounted() {
        if (this.user.phone_number_verified) return;

        this.show = this.userShow;

        this.send();
    },

    methods: {
        send(second = false) {
            this.$axios.get(
                "send_otp"
                )
                .then(() => {
                    if (second) this.success = true;
                })
                .catch(({response}) => {
                    if (second) this.error = response.data;
                })
                .finally(() => {
                    this.processing = false;
                });
        },

        sendPhoneChange() {
            this.$axios.patch("user_account", this.changeForm)
                .then(({ data }) => {
                    this.$store.commit("setUser", { user: data.user, token: data.token });
                    this.$store.commit("setCompany", data.company);

                    this.changePhone = false;

                    this.send();
                })
        },

        verify() {
            this.processing = true;

            this.$axios.post(
                "verify_otp",
                this.form
                )
                .then(({data}) => {
                    this.$store.commit("setUser", data);

                    this.show = false;
                })
                .catch((err) => {
                    this.errors = err.response.data
                })
                .finally(() => {
                    this.processing = false;
                });
        }
    }
};
</script>

<style scoped>

</style>