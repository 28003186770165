<template>
  <div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <div class="absolute inset-y-0 left-0 flex items-start">
        <label class="sr-only">Code</label>
        <div class="absolute h-full flex items-center text-gray-500">
          <select @change="checkFeedback" v-model="countryCode"
                  class="appearance-none h-full py-0 pl-3 pr-7 border-gray-300 border-r bg-transparent sm:text-sm rounded-md">
            <option value="GR">+30</option>  <!-- Greece (EL is the VAT code) -->
            <option value="NL">+31</option>  <!-- Netherlands -->
            <option value="BE">+32</option>  <!-- Belgium -->
            <option value="FR">+33</option>  <!-- France -->
            <option value="ES">+34</option>  <!-- Spain -->
            <option value="HU">+36</option>  <!-- Hungary -->
            <option value="IT">+39</option>  <!-- Italy -->
            <option value="RO">+40</option>  <!-- Romania -->
            <option value="AT">+43</option>  <!-- Austria -->
            <option value="GB">+44</option>  <!-- United Kingdom -->
            <option value="DK">+45</option>  <!-- Denmark -->
            <option value="SE">+46</option>  <!-- Sweden -->
            <option value="PL">+48</option>  <!-- Poland -->
            <option value="DE">+49</option>  <!-- Germany -->
            <option value="PT">+351</option> <!-- Portugal -->
            <option value="LU">+352</option> <!-- Luxembourg -->
            <option value="IE">+353</option> <!-- Ireland -->
            <option value="MT">+356</option> <!-- Malta -->
            <option value="CY">+357</option> <!-- Cyprus -->
            <option value="FI">+358</option> <!-- Finland -->
            <option value="BG">+359</option> <!-- Bulgaria -->
            <option value="LT">+370</option> <!-- Lithuania -->
            <option value="LV">+371</option> <!-- Latvia -->
            <option value="EE">+372</option> <!-- Estonia -->
            <option value="SI">+386</option> <!-- Slovenia -->
            <option value="CZ">+420</option> <!-- Czech Republic -->
            <option value="SK">+421</option> <!-- Slovakia -->

          </select>
          <svg class="right-0 absolute mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
          </svg>
        </div>
      </div>
      <b-input :disabled="disabled" @focus="valid = true" @blur="emitPhoneNumber" class="pl-20" type="text"
               v-model="phoneNumber" :error="typeof valid === 'string' || !!error"/>
    </div>
    <p v-if="typeof valid === 'string'" class="text-sm mt-1 text-red-500">{{ valid }}</p>
  </div>
</template>

<script>
import parsePhoneNumber, {isValidNumber} from "libphonenumber-js";
import BInput from "@/modules/Input";

export default {
  name: "BPhoneInput",
  components: {BInput},
  data: () => ({
    countryCode: "NL",
    phoneNumber: "",
    valid: true
  }),

  props: ['error', 'disabled'],

  created() {
    if (this.$attrs.value) {
      this.phoneNumber = parsePhoneNumber(this.$attrs.value).nationalNumber
    }
  },

  methods: {
    emitPhoneNumber() {
      this.feedback();
      if (isValidNumber(this.phoneNumber, this.countryCode)) {
        this.$emit("input", parsePhoneNumber(this.phoneNumber, this.countryCode).number);
      } else {
        this.$emit("input", undefined);
      }
    },

    feedback() {
      this.valid = !isValidNumber(this.phoneNumber, this.countryCode)
          ? "Dit telefoonnummer is niet geldig!"
          : true;
    },

    checkFeedback() {
      if (!this.phoneNumber) {
        this.valid = true;
      } else {
        this.emitPhoneNumber();
      }
    }
  },

  watch: {
    // countryCode() { this.emitPhoneNumber(); },
    // phoneNumber() { this.emitPhoneNumber(); }
  }
};
</script>

<style scoped>

</style>