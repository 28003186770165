<template>
  <registration-layout max-width="max-w-xl">
    <template #title>
      <b-text
          id="kvk_extract/title"
          fallback="Upload hier jouw { cocType } uittreksel."
          :mapping="{cocType}"
      />
    </template>
    <template #subtitle v-if="redirectToExtractLink">
      <b-text
          id="kvk_extract/subtitle"
          fallback="Nog geen uittreksel? Klik <a href='{link}'>hier</a> om er een aan te schaffen."
          :mapping="{ link: redirectToExtractLink, cocType: cocType }"
      />
    </template>

    <template>
      <input accept="application/pdf" @change.prevent="fileSelect" type="file" class="hidden" ref="file">
      <div
          @drop.prevent="drop"
          @dragover.prevent="() => {}"
          @click.prevent="$refs.file.click()"
          class="flex flex-col w-full border-4 border-dashed border-gray-300 rounded-lg text-center mt-5 cursor-pointer"
          :class="inputFile ? 'p-5' : 'p-10'"
      >
        <p v-if="!inputFile" class="font-bold text-gray-700">Klik hier of drop hier je uittreksel.</p>

        <embed v-else-if="base64" :src="base64" class="w-full h-96">
      </div>

      <div v-if="inputFile" class="grid-cols-2 grid text-center gap-y-3">
        <p class="col-span-2 font-bold text-xl text-gray-700">Klopt dit uittreksel?</p>

        <b-link :disabled="processing" @click="$refs.file.click()"
                class="col-span-2 sm:col-span-1 flex justify-center items-center">Nee, kies opnieuw
        </b-link>

        <b-button :disabled="processing" @click="save" class="col-span-2 sm:col-span-1">Ja, ga door</b-button>
      </div>
    </template>
  </registration-layout>
</template>

<script>
import RegistrationLayout from "@/layouts/RegistrationLayout";
import {mapGetters} from "vuex";
import BLink from "@/modules/Link";
import BButton from "@/modules/Button";
import {redirectToCompanyStep} from "@/router";
import BText from "@/modules/Text";

export default {
  name: "KvKExtract",

  components: {
    BText,
    BButton,
    BLink,
    RegistrationLayout
  },

  data: () => ({
    hovering: false,
    inputFile: undefined,
    base64: undefined,

    processing: false
  }),

  async mounted() {
    if (this.company.kvk_extract) {
      this.base64 = this.inputFile = this.company.kvk_extract;
    }
  },

  computed: {
    ...mapGetters({
      company: "company"
    }),

    cocType() {
      return (this.company.country_code === "NL" || this.company.country_code !== "BE") ? "KvK" : "KBO";
    },

    redirectToExtractLink() {
      if (this.company.country_code === "NL") {
        return `https://www.kvk.nl/orderstraat/bestel-overzicht/?kvknummer=${this.company.kvk_number}&productgroep=Uittreksel&product=Digitaal+gewaarmerkt+uittreksel`;
      } else if (this.company.country_code === "BE") {
        return "https://www.xerius.be/nl-be/zelfstandigen/tools-en-documenten/kbo-uittreksel";
      } else {
        return null;
      }
    }
  },

  methods: {
    drop(event) {
      this.change(event.dataTransfer.files[0]);

      console.log(this.$refs.file.files, this.file);

      event.preventDefault();
    },

    async change(file) {
      this.inputFile = this.base64 = undefined;

      this.$nextTick(async () => {
        this.inputFile = file;

        this.base64 = await this.readFile(file);
      });
    },

    readFile(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(false);
      });
    },

    fileSelect(event) {
      this.change(event.target.files[0]);
    },

    save() {
      this.processing = true;

      let data = new FormData();

      data.append("kvk_extract", this.inputFile);

      console.log(typeof this.inputFile);

      if (typeof this.inputFile === "string") {
        this.$axios.patch(
            `company/${this.company.id}/`,
            {step: "USER_DETAILS"}
        )
            .then(({data}) => {
              this.$store.commit("setCompany", data);

              this.$router.push(redirectToCompanyStep(this.$router));
            })
            .finally(() => {
              this.processing = false;
            });
      } else {
        this.$axios.patch(
            "company/" + this.$store.state.company.id + "/",
            data
        )
            .then(({data}) => {
              this.$store.commit("setCompany", data);

              this.$router.push(redirectToCompanyStep(this.$router));
            })
            .catch(() => {
              this.error = true;
            })
            .finally(() => {
              this.processing = false;
            });
      }

    }
  }
};
</script>

<style scoped>

</style>