<template>
  <registration-layout>
    <template #title>
      <b-text
          :id="isValid ? 'vat_number/success/title' : 'vat_number/title'"
          :fallback="isValid ? 'Gevonden' : 'BTW-nummer'"
      />
    </template>
    <template #subtitle>
      <b-text
          :id="isValid ? 'vat_number/success/subtitle' : (processing ? 'vat_number/loading/subtitle' : 'vat_number/subtitle')"
          :fallback='isValid
                    ? "We hebben de volgende gegevens gevonden aan de hand van jouw BTW-nummer. Klopt dit?"
                    : (processing
                        ? "We zijn even jouw BTW-nummer aan het opzoeken, klein momentje."
                        : "Vul jouw BTW-nummer hier in, zodat we jouw gegevens kunnen verifiëren aan de hand van het BTW-nummerregister."
                    )
                '
      />
    </template>

    <template>
      <form
          v-if="!result"
          @submit.prevent="submit"
      >
        <div class="rounded-md bg-red-50 p-4 mb-4"
             v-if="texts && texts['vat_number/error'] && Object.keys(errors).length > 0">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
            <b-text id="vat_number/error" class="ml-3 text-sm"/>
          </div>
        </div>

        <div v-if="!processing" class="grid grid-cols-1 gap-y-4">
          <div>
            <b-label>BTW-nummer</b-label>
            <b-input ref="vatNumber" :disabled="processing" placeholder="Bijvoorbeeld: NL123456789B01" type="text"
                     v-model="form.vat_number" :error="errors.vat_number"/>
            <b-input-error :error="errors.vat_number"/>
          </div>

          <div class="grid md:grid-cols-2 grid-cols-1">
            <b-button :disabled="processing" type="submit" class="col-start-2">
              Controleren
            </b-button>
          </div>
        </div>

        <div v-else class="flex justify-center">
          <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000">
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(1 1)" stroke-width="2">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s"
                                    repeatCount="indefinite"/>
                </path>
              </g>
            </g>
          </svg>
        </div>
      </form>

      <div
          v-if="isValid"
      >
        <div v-if="errors.general" class="bg-red-50 border-2 border-red-300 p-3">
          <p>{{ errors.general }}</p>
        </div>

        <div
            class="border-2 rounded-md border-gray-200 bg-gray-50 p-3"
        >
          <p>
            <b>Naam:</b>
            {{ result.company.company_name }}
          </p>
          <p class="mt-1">
            <b>Adres:</b>
            {{ result.company.company_address }}
          </p>
        </div>

        <div class="grid sm:grid-cols-2 grid-cols-1 mt-4 items-center">
          <b-link @click="result = form.vat_number = undefined" class="pl-3">
            Nee, ga terug
          </b-link>
          <b-button @click="save" type="button" :disabled="processing">Dit klopt!</b-button>
        </div>
      </div>
    </template>
  </registration-layout>
</template>

<script>
import RegistrationLayout from "@/layouts/RegistrationLayout";
import BLabel from "@/modules/Label";
import BInput from "@/modules/Input";
import BInputError from "@/modules/InputError";
import BButton from "@/modules/Button";
import BLink from "@/modules/Link";
import {redirectToCompanyStep} from "@/router";
import {mapGetters} from "vuex";
import BText from "@/modules/Text";

export default {
  name: "VATNumber",
  components: {
    BText,
    BLink,
    BButton,
    BInputError,
    BInput,
    BLabel,
    RegistrationLayout
  },

  data: () => ({
    form: {
      vat_number: ""
    },

    processing: false,

    errors: {},

    result: undefined
  }),

  created() {
    if (this.company && this.company.vat_number) {
      this.form.vat_number = this.company.vat_number;
    }
  },

  computed: {
    ...mapGetters({
      company: "company",
      texts: "texts",
    }),

    isValid() {
      return this.result && this.result.valid;
    }
  },

  methods: {
    submit() {
      this.processing = true;
      this.errors = {};

      this.$axios.post("vat_number", this.form)
          .then(({data}) => {
            this.result = data.result;
          })
          .catch(({response}) => {
            this.errors = response.data;
          })
          .finally(() => {
            this.processing = false;
          });
    },

    save() {
      this.processing = true;
      const companyData = this.result.company;

      (this.company
          ? this.$axios.patch(`company/${this.company.id}/`, companyData)
          : this.$axios.post("company/", companyData))
          .then(({data}) => {
            this.$store.commit("setCompany", data);

            this.$router.push(redirectToCompanyStep(this.$router));
          })
          .catch(() => {
            this.errors["general"] = "Er is iets fout gegaan! Probeer het nog een keer of neem contact met ons op!";
          })
          .finally(() => {
            this.processing = false;
          });
    }
  }
};
</script>

<style scoped>

</style>
