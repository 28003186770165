<template>
    <registration-layout>
        <template #title>
            <b-text
                id="success/title"
                fallback="Gelukt!"
            />
        </template>
        <template #subtitle>
            <b-text
                id="success/subtitle"
                fallback="Je registratie is voltooid."
            />
        </template>
    </registration-layout>
</template>

<script>
import RegistrationLayout from "@/layouts/RegistrationLayout";
import BText              from "@/modules/Text";
export default {
name: "Success",
    components: {
        BText,
        RegistrationLayout}
}
</script>

<style scoped>

</style>