<template>
    <a @click="$emit('click')" :href="href" class="hover:text-primary-dark uppercase text-primary font-semibold text-sm cursor-pointer">
        <slot></slot>
    </a>
</template>

<script>
export default {
    name: "BLink",
    props: ["href"]
};
</script>

<style scoped>

</style>