<template>
    <registration-layout max-width="max-w-2xl" v-if="!waiting">
        <template #title>
            <b-text
                id="sign_dv/title"
                fallback="Directe vertegenwoordiging"
            />
        </template>
        <template #subtitle>
            <b-text
                id="sign_dv/subtitle"
                fallback="Door onderstaande te ondertekenen, geef je ons en onze partij die met de douane werkt, machtiging om jouw goederen in te klaren uit jouw naam."
            />
        </template>

        <template>
            <div id="sign-doc"></div>
            <div v-if="!url" class="flex flex-col items-center mt-4 pt-4 border-t">
                <p class="text-2xl mb-5">Laden..</p>

                <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000">
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(1 1)" stroke-width="2">
                            <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        </template>
    </registration-layout>

    <registration-layout v-else>
        <template #title>
            <b-text
                id="sign_dv/saving/title"
                fallback="Bezig met opslaan..."
            />
        </template>
        <template #subtitle>
            <b-text
                id="sign_dv/saving/subtitle"
                fallback="Dit kan wel even duren, pak vooral een kopje koffie in de tussentijd."
            />
        </template>

        <template>
            <div class="flex justify-center">
                <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000">
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(1 1)" stroke-width="2">
                            <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        </template>
    </registration-layout>
</template>

<script>
import RegistrationLayout      from "@/layouts/RegistrationLayout";
import {mapGetters}            from "vuex";
import {redirectToCompanyStep} from "@/router";
import BText                   from "@/modules/Text";

export default {
    name: "SignDV",
    components: {
        BText,
        RegistrationLayout
    },

    computed: {
        ...mapGetters({
            company: "company",
            user: "user"
        })
    },

    data: () => ({
        url: undefined,
        waiting: false,
        iframe: undefined,
    }),

    mounted() {
        this.$axios.post(`company/${this.company.id}/embed_dv/`).then((response) => {
            this.url = response.data.url
            this.mountIFrame()
        })
    },

    methods: {
        submit() {
            this.iframe.remove()
            this.waiting = true;
            this.$axios.get("company/" + this.company.id + "/complete/")
                .then(({data}) => {
                    this.$store.commit("setCompany", data);

                    this.$router.push(redirectToCompanyStep(this.$router));
                }).catch(() => {
                    setTimeout(this.submit, 1000)
                });
        },

        mountIFrame() {
            this.iframe = document.createElement("iframe");
            this.iframe.frameBorder = "0";
            this.iframe.src = this.url;
            const node = document.getElementById("sign-doc");
            node.style.height = "700px";
            node.style.width = "100%";
            this.iframe.height = "100%";
            this.iframe.width = "100%";
            node.append(this.iframe);

            const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
            const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
            window[eventMethod](messageEvent, this.handleSuccess, false);
        },

        handleSuccess(e) {
            if (e && e.data && e.source === this.iframe.contentWindow) {
                if (e.data === "success") {
                    this.submit();
                }
            }
        }
    }
};
</script>

<style scoped>

</style>