<template>
    <p class="text-xs text-red-500 mt-1">{{ errorMsg }}</p>
</template>

<script>
export default {
    name: "BInputError",
    props: {
        error: {
            type: [Array, String, null],
            required: false,
        }
    },

    computed: {
        errorMsg() {
            if (this.error) {
                return Array.isArray(this.error) ? this.error[0] : this.error;
            }
            else return undefined;
        }
    }
};
</script>

<style scoped>

</style>