<template>
  <div class="border border-gray-300 bg-gray-50 p-3 rounded text-gray-700">
    <p class="font-bold text-lg">{{ kvk.name }}</p>
    <div class="text-sm">
      <p><b>{{ cocType }} nummer: </b> {{ kvk.number }}</p>
      <div v-if="kvk.street">
        <p><b>Adres: </b></p>
        <p>{{ kvk.street }} {{ kvk.house_number }} {{ kvk.house_number_ext }}</p>
        <p>{{ kvk.postcode }} {{ kvk.city }}</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "KvKResult",

  props: ["kvk", "cocType"]
};
</script>

<style scoped>

</style>