<template>
    <div class="relative flex items-center">
        <b-input
            @input="val => $emit('input', val)"
            :type="hidePassword ? 'password' : 'text'"
            :value="value"
            :disabled="disabled"
            :class="[
          'appearance-none shadow-sm py-2 px-2  border block w-full sm:text-sm border-gray-300 rounded-md',
          !!error ? 'border-red-500' : 'border-gray-300 focus:border-primary'
      ]"
        />

        <svg v-if="hidePassword" @click="$emit('password')" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
        </svg>

        <svg v-if="!hidePassword" @click="$emit('password')" :class="iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
        </svg>
    </div>
</template>

<script>
import BInput from "@/modules/Input";

export default {
    name: "BPasswordInput",
    components: {BInput},

    props: {
        value: String,
        error: {
            type: null,
            default: false
        },
        hidePassword: {
            default: true,
            type: Boolean,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },

    data: () => ({
        hidden: true,
        iconClass: "h-5 w-5 hover:text-primary cursor-pointer absolute right-0 mr-2 text-gray-300"
    }),

    watch: {
        hidePassword() {
            console.log(this.hidden || this.hidePassword);
        }
    }
};
</script>

<style scoped>

</style>