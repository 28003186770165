<template>
    <b-modal :show="show" @close="$emit('close')">
        <div class="py-4">
            <div class="head px-4 pb-2 border-b border-gray-200">
                <p class="font-bold text-xl text-gray-700">Inloggen</p>
            </div>

            <div class="px-4 pt-4 pb-2">
                <form @submit.prevent="login" class="grid grid-cols-1 gap-y-4">
                    <div>
                        <b-label>E-mailadres</b-label>
                        <b-input type="email" v-model="form.email" :error="errors.email" />
                        <b-input-error :error="errors.email" />
                    </div>

                    <div>
                        <b-label>Wachtwoord</b-label>
                        <b-password-input :hide-password="hidePassword" v-model="form.password" :error="errors.password" />
                        <b-input-error :error="errors.password" />
                    </div>

                    <div class="grid sm:grid-cols-2 grid-cols-1 mt-2">
                        <b-button :disabled="processing" type="submit" class="sm:col-start-2">Inloggen</b-button>
                    </div>
                </form>
            </div>
        </div>
    </b-modal>
</template>

<script>
import BModal                  from "@/modules/Modal";
import BLabel                  from "@/modules/Label";
import BInput                  from "@/modules/Input";
import BPasswordInput          from "@/modules/PasswordInput";
import BButton                 from "@/modules/Button";
import BInputError             from "@/modules/InputError";
import {redirectToCompanyStep} from "@/router";

export default {
    name: "BLoginModal",
    components: {
        BInputError,
        BButton,
        BPasswordInput,
        BInput,
        BLabel,
        BModal
    },

    props: ["show"],

    data: () => ({
        form: {
            email: "",
            password: ""
        },

        errors: {},

        hidePassword: true,

        processing: false
    }),

    methods: {
        login() {
            this.processing = true;

            this.$axios.post("login", this.form)
                .then(({data}) => {
                    this.$store.commit("setUser", {
                        user: data.user,
                        token: data.token
                    });

                    if (data.company)
                        this.$store.commit('setCompany', data.company)

                    this.$emit("close");
                    this.$router.push(redirectToCompanyStep(this.$router));
                })
                .catch(({response}) => {
                    this.errors = response.data;
                })
                .finally(() => {
                    this.processing = false;
                });
        }
    }
};
</script>

<style scoped>

</style>