<template>
    <input
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        @focus="$emit('focus')"
        :value="value"
        :class="[
          'appearance-none shadow-sm py-2 px-2  border block w-full sm:text-sm border-gray-300 rounded-md',
          !!error ? 'border-red-500' : 'border-gray-300 focus:border-primary'
      ]"
    />
</template>

<script>
export default {
    name: "BInput",
    props: {
        value: null,
        error: {
            type: null,
            default: false
        }
    }
};
</script>

<style scoped>

</style>