<template>
    <span class="text" v-html="html"></span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "BText",

    props: {
        id: String,
        fallback: String,
        mapping: Object
    },

    computed: {
        ...mapGetters({
            texts: "texts"
        }),

        html() {
            let html = this.texts[this.id] ? this.texts[this.id] : this.fallback;

            let keys = Array.from(html.matchAll(/({).*(})/g), m => m[0]);

            for (let key of keys) {
                key = key.replaceAll(/[{}]/g, "").trim();
                if (this.mapping[key]) {
                    html = html.replaceAll(new RegExp(`({).*(${key}).*(})`, "g"), this.mapping[key]);
                }
            }

            return html;
        }
    }
};
</script>

<style>
.text a {
    color: rgb(69, 83, 240);
    font-weight: bold;
}

.text a:hover {
    color: rgb(48, 58, 168);
}
</style>