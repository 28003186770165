import Vue       from "vue";
import App       from "./App.vue";
import store     from "./store";
import router    from "./router";
import axios     from "axios";
import PortalVue from "portal-vue";
import "./assets/tailwind.css";

Vue.use(PortalVue);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios.create({
	baseURL: process.env.NODE_ENV === "production" ? "https://forwarding.boloo.co/api/v2/" : "http://localhost:8000/api/v2/"
});

new Vue({
	store,
	router,
	render: h => h(App)
}).$mount("#app");

