<template>
    <label class="text-sm text-gray-700 font-semibold">
        <slot></slot>
    </label>
</template>

<script>
export default {
    name: "BLabel"
};
</script>

<style scoped>

</style>