import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: () => ({
        user: undefined,
        token: undefined,

        company: undefined,

        texts: undefined,
    }),

    getters: {
        user(state) {
            return state.user;
        },

        token(state) {
            return state.token;
        },

        company(state) {
            return state.company;
        },

        texts(state) {
            return state.texts;
        },
    },

    mutations: {
        setUser(state, {
            user,
            token
        }) {
            state.user = user;
            state.token = token;

            Vue.prototype.$axios.defaults.headers["Authorization"] = "Token " + token.key;

            localStorage.setItem("boloo_token", token.key);
        },

        setCompany(state, company) {
            state.company = company;
        },

        logout(state) {
            state.user = state.token = state.company = undefined;
            localStorage.removeItem("boloo_token");
        },

        setTexts(state, texts) {
            state.texts = texts;
        }
    },

    actions: {
        fetchCurrentUser({commit}, token) {
            return Vue.prototype.$axios.get("current", {headers: {"Authorization": "Token " + token}})
                .then(({data}) => {
                    let token = data.token,
                        user = data.user,
                        company = data.company;
                    console.log(data)

                    commit("setUser", {
                        token,
                        user
                    });

                    if (company) commit("setCompany", company);

                    return true;
                })
                .catch(() => {
                    return false;
                });
        },

        fetchTexts({commit}) {
            return Vue.prototype.$axios.get("texts")
                .then(({data}) => {
                    commit("setTexts", data);
                });
        }
    }
});
