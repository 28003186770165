<template>
    <button
        @click="$emit('click')"
        :type="type"
        class="bg-primary text-white transition duration-150 ease-in-out hover:bg-primary-dark tracking-widest uppercase rounded text-sm shadow disabled:opacity-50 disabled:cursor-default"
        :class="padding"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "BButton",

    props: {
        padding: {
            type: String,
            default: "px-4 py-3"
        },
        type: {
            type: String,
            default: "submit"
        }
    }
};

</script>

<style scoped>

</style>