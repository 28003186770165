import Vue         from "vue";
import VueRouter   from "vue-router";
import UserAccount from "@/pages/UserAccount";
import VATNumber   from "@/pages/VATNumber";
import EORINumber  from "@/pages/EORINumber";
import KvKResults  from "@/pages/KvKResults";
import KvKExtract  from "@/pages/KvKExtract";
import UserDetails from "@/pages/UserDetails";
import SignDV      from "@/pages/SignDV";
import Success     from "@/pages/Success";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: {name: "userAccount"},
		meta: {requiresAuth: false}
	},
	{
		path: "/user",
		name: "userAccount",
		component: UserAccount,
		meta: {
			requiresAuth: false,
			stepName: "USER_ACCOUNT"
		}
	},
	{
		path: "/vat",
		name: "vatNumber",
		component: VATNumber,
		meta: {
			requiresAuth: true,
			stepName: "VAT_NUMBER"
		}
	},
	{
		path: "/eori",
		name: "eoriNumber",
		component: EORINumber,
		meta: {
			stepName: "EORI_NUMBER",
			requiresAuth: true
		}
	},
	{
		path: "/kvk",
		name: "kvkNumber",
		component: KvKResults,
		meta: {
			stepName: "KVK_NUMBER",
			requiresAuth: true
		}
	},
	{
		path: "/extract",
		name: "kvkExtract",
		component: KvKExtract,
		meta: {
			stepName: "KVK_EXTRACT",
			requiresAuth: true
		}
	},
	{
		path: "/details",
		name: "userDetails",
		component: UserDetails,
		meta: {
			stepName: "USER_DETAILS",
			requiresAuth: true
		}
	},
	{
		path: "/sign",
		name: "signDv",
		component: SignDV,
		meta: {
			stepName: "SIGN_DV",
			requiresAuth: true
		}
	},
	{
		path: "/success",
		name: "success",
		component: Success,
		meta: {
			stepName: "SUCCESS",
			requiresAuth: true
		}
	}
];

const router = new VueRouter({
	routes,
	mode: "history"
});

router.beforeEach(async (from, to, next) => {
	let token = localStorage.getItem("boloo_token");
	
	if (!from.meta.requiresAuth && !token) {
		return next();
	}
	
	if (!token && from.meta.requiresAuth) {
		return next({name: "userAccount"});
	}
	
	let loggedIn = router.app.$store.getters["user"];
	
	if (!loggedIn && token) {
		let loggedIn = await router.app.$store.dispatch("fetchCurrentUser", token);
		if (!loggedIn) {
			window.localStorage.removeItem("boloo_token");
			return next({name: "userAccount"});
		} else {
			return next(redirectToCompanyStep(router));
		}
	}
	
	// if ((from.meta.requiresAuth && loggedIn) || (!from.meta.requiresAuth && !loggedIn)) {
		return next();
	// } else {
	// 	return next(redirectToCompanyStep(router));
	// }
});

export function redirectToCompanyStep(router) {
	let company = router.app.$store.getters["company"];
	if (!company) {
		return router.getRoutes().filter(r => r.name === "vatNumber")[0].path;
	} else {
		return router.getRoutes().filter(r => r.meta.stepName === company.step)[0].path;
	}
}

export default router;
